module.exports = [{
      plugin: require('/codebuild/output/src594373192/src/bureau-digital-site/node_modules/gatsby-plugin-hubspot/gatsby-browser.js'),
      options: {"plugins":[],"trackingCode":"5616108"},
    },{
      plugin: require('/codebuild/output/src594373192/src/bureau-digital-site/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-136399111-1"},
    },{
      plugin: require('/codebuild/output/src594373192/src/bureau-digital-site/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/codebuild/output/src594373192/src/bureau-digital-site/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
